<template>
  <Modal nome="importarAlunos">
    <div class="cadastro" v-if="!state.mostrarImportados">
      <!-- Arquivo CSV -->
      <label class="link um">
        Envie o arquivo .csv
        <span>*</span>
        <a href="https://arquivos.levelmember.com/imagens/tutoriais/importar-alunos.pdf" target="_blank">
          <Svgs nome="duvida" />
          Como enviar?
        </a>
      </label>
      <div class="inputImagem" :class="{ carregar: state.loading }" @click="abrirInputFile">
        <span></span>
        <div class="previa">
          <Svgs v-if="state.arquivo" nome="check" />
          <Svgs v-if="!state.arquivo" nome="documento" />
        </div>
        <div class="texto">
          <h3>{{ state.nomeArquivo || 'Subir Arquivo .csv' }}</h3>
          <p>{{ state.tamanhoArquivo || 'Clique aqui e suba a planilha de alunos' }}</p>
        </div>
        <input type="file" accept=".csv" hidden @change="selecionarImagem" ref="inputFile" />
      </div>
      <!-- Cursos -->
      <label>
        Cursos que os usuários terão
        <span>*</span>
      </label>
      <Checklist :dados="state.cursos" />
      <!-- Data vencimento -->
      <label>
        Acesso aos cursos até:
        <span>*</span>
      </label>
      <div class="input">
        <Svgs class="icone" nome="relogio" />
        <input type="tel" spellCheck="false" placeholder="00/00/0000" v-mask="`##/##/####`" v-model="state.dataVencimento" />
      </div>
      <SalvarModal nome="Importar Alunos" @click="importarAlunos()" />
    </div>
    <!-- Importados -->
    <div class="importados" v-else>
      <h3>Importação de {{ state.importadosTotal || 0 }} alunos</h3>
      <div class="quadrinhos">
        <div class="quadro">
          <h4 class="sucesso">{{ state.importadosConcluido || 0 }}</h4>
          <p>Importados com</p>
          <p>Sucesso</p>
        </div>
        <div class="quadro">
          <h4 class="erro">{{ state.importadosErro || 0 }}</h4>
          <p>Não foram</p>
          <p>importados</p>
        </div>
      </div>
      <SalvarModal nome="Fechar quadro" @click="fecharQuadro()" />
    </div>
  </Modal>
</template>

<script setup>
import { reactive, onMounted, inject, ref } from 'vue'
import { useStoreCursos, useStoreAreas, useStoreModal } from '@stores'
import Checklist from '@components/global/elementos/Checklist.vue'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'
import ApiImportacoes from '@/api/produtor/api-importacoes.js'

const storeAreas = useStoreAreas()
const storeCursos = useStoreCursos()
const storeModal = useStoreModal()
const emitter = inject('emitter')
const inputFile = ref(null)

const state = reactive({
  cursos: [],
  dataVencimento: '',
  arquivo: '',
  nomeArquivo: '',
  tamanhoArquivo: '',
  importadosTotal: '',
  importadosErro: '',
  importadosConcluido: '',
  loading: false,
  mostrarImportados: false
})

function abrirInputFile() {
  inputFile.value.click()
}

function formatarTamanhoArquivo(tamanho) {
  const i = Math.floor(Math.log(tamanho) / Math.log(1024))
  const tamanhos = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  return (tamanho / Math.pow(1024, i)).toFixed(2) + ' ' + tamanhos[i]
}

function selecionarImagem(arquivo) {
  const arquivoSelecionado = arquivo.target.files[0]
  state.loading = true

  if (arquivoSelecionado) {
    state.nomeArquivo = arquivoSelecionado.name
    state.tamanhoArquivo = formatarTamanhoArquivo(arquivoSelecionado.size)
    state.arquivo = arquivoSelecionado
    state.loading = false
  }
}

async function carregarTodosOsCursos() {
  const idArea = storeAreas.area?._id
  if (idArea) {
    await storeCursos.receberTodos(idArea)
    state.cursos = storeCursos.cursos.map((curso) => ({
      nome: curso.nome,
      id: curso._id,
      selecionado: false
    }))
  }
}

function carregarCampos() {
  carregarTodosOsCursos()

  const hoje = new Date()
  const dia = String(hoje.getDate()).padStart(2, '0')
  const mes = String(hoje.getMonth() + 1).padStart(2, '0')
  const ano = hoje.getFullYear()

  state.dataVencimento = `${dia}/${mes}/${ano}`
}

function fecharQuadro() {
  limparCampos()
  storeModal.fecharModal('importarAlunos')
}

function limparCampos() {
  state.cursos = []
  state.arquivo = ''
  state.nomeArquivo = ''
  state.tamanhoArquivo = ''
  state.importadosConcluido = ''
  state.importadosErro = ''
  state.importadosTotal = ''
  state.mostrarImportados = false
}

function importarAlunos() {
  if (!validarDados()) return

  const idArea = storeAreas.area?._id

  let [dia, mes, ano] = state.dataVencimento.split('/')
  let dataAcesso = new Date(`${ano}-${mes}-${dia}T00:00:00`)

  const hoje = new Date()
  hoje.setHours(0, 0, 0, 0)

  if (dataAcesso < hoje) {
    dataAcesso = hoje
  }

  dataAcesso.setDate(dataAcesso.getDate() + 1)
  dataAcesso.setHours(0, 0, 0, 0)

  const payload = {
    idsCursos: state.cursos.filter((curso) => curso.selecionado).map((curso) => curso.id),
    dataVencimento: dataAcesso.toISOString(),
    arquivo: state.arquivo
  }

  ApiImportacoes.importarAlunos(idArea, payload)
    .then((resp) => {
      state.importadosTotal = resp.total || ''
      state.importadosErro = resp.erro || ''
      state.importadosConcluido = resp.sucesso || ''
      state.mostrarImportados = true
    })
    .catch((erro) => {
      console.error(erro)
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao importar alunos: ' + (erro.message || erro) })
      state.mostrarImportados = true
    })
    .finally(() => {
      state.loading = false
    })
}

function validarDados() {
  let mensagem
  if (!state.cursos || !state.dataVencimento || !state.arquivo) {
    mensagem = 'Por favor, preencha todos os campos obrigatórios'
  } else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
  emitter.on('carregarCampos', carregarCampos)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

label.um {
  margin: 0 0 10px 0;
}

label.link {
  display: flex;
  align-items: center;
}

label a {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-verde);
  margin: 0 0 0 15px;
  transition: all 0.3s;
}

label a:hover {
  opacity: 0.6;
}

label a svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-verde);
  margin: 0 5px 0 0;
}

button.salvar {
  margin: 20px 0 0 0;
}

.cadastro {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.importados {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.importados h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
}

.importados .quadrinhos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 30px 0 0 0;
}

.importados .quadrinhos .quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: calc(50% - 8px);
  padding: 40px 20px;
}

.importados .quadrinhos .quadro h4 {
  font-family: var(--bold);
  font-size: var(--f5);
  margin: 0 0 10px 0;
}

.importados .quadrinhos .quadro h4.sucesso {
  color: var(--cor-verde);
}

.importados .quadrinhos .quadro h4.erro {
  color: var(--cor-vermelho);
}

.importados .quadrinhos p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 5px 0 0 0;
}

@media screen and (max-width: 1000px) {
  .importados {
    width: 100%;
    height: 100dvh;
    padding: 30px 20px 20px 20px;
  }
}
</style>
