<template>
  <section class="integracoes" v-if="storeIntegracoes.integracoes?.length > 0">
    <div v-for="integracao in storeIntegracoes.integracoes" :key="integracao._id" class="integracao">
      <div class="nome">
        <div class="plataforma" :class="integracao.gateway">
          <Svgs :nome="`logo-` + integracao.gateway" />
        </div>
        <h3>{{ integracao.nome }}</h3>
      </div>
      <div class="dados">
        <div class="dado" :class="{ ativo: integracao.ativo }">
          <span></span>
          <p>{{ integracao.ativo ? 'Ativo' : 'Inativo' }}</p>
        </div>
        <div class="dado">
          <Svgs nome="curso" />
          <p>Libera {{ textoCursos(integracao.idsCursos?.length) }}</p>
        </div>
        <div class="dado">
          <Svgs nome="relogio" />
          <p>Criada {{ cadastroData(integracao.dataRegistro) }}</p>
        </div>
      </div>
      <div class="opcoes">
        <button class="excluir" @click="abrirModalEdicao('excluirIntegracao', 'integracao', `Excluir configuração com ${integracao.gateway}`, integracao._id)">
          <Svgs nome="excluir" />
        </button>
        <button class="editar" @click="abrirModalEdicao('editarIntegracao', 'integracao', `Configurar com ${integracao.gateway}`, integracao._id)">
          <Svgs nome="editar" />
        </button>
        <button class="entrar" @click="abrirModalEdicao('editarWebhook', 'integracao', `Webhook da ${integracao.gateway}`, integracao._id)">
          <p>Ver Webhook</p>
          <Svgs nome="integracao" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal, useStoreIntegracoes } from '@stores'
import Svgs from '@svgs'

const storeIntegracoes = useStoreIntegracoes()
const storeModal = useStoreModal()

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}

const textoCursos = (quantidade) => {
  return `${quantidade} ${quantidade === 1 ? 'curso' : 'cursos'}`
}

function cadastroData(data) {
  const agora = new Date()
  const dataNotificacao = new Date(data)
  const diferencaMs = agora - dataNotificacao
  const diferencaMinutos = Math.floor(diferencaMs / 60000)
  const diferencaHoras = Math.floor(diferencaMs / 3600000)

  if (diferencaMinutos < 1) {
    return 'há segundos'
  } else if (diferencaMinutos < 60) {
    return `há ${diferencaMinutos} minuto${diferencaMinutos > 1 ? 's' : ''}`
  } else if (diferencaHoras < 24) {
    return `há ${diferencaHoras} hora${diferencaHoras > 1 ? 's' : ''}`
  } else {
    const day = String(dataNotificacao.getDate()).padStart(2, '0')
    const month = String(dataNotificacao.getMonth() + 1).padStart(2, '0')
    const year = dataNotificacao.getFullYear()
    const hours = String(dataNotificacao.getHours()).padStart(2, '0')
    const minutes = String(dataNotificacao.getMinutes()).padStart(2, '0')

    return `${day}/${month}/${year} às ${hours}h${minutes}`
  }
}
</script>

<style scoped>
section.integracoes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px 0 20px;
}

.integracao {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  margin: 0 0 20px 0;
}

.integracao .nome {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.integracao .nome .plataforma {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  border-radius: 10px;
}

.integracao .nome .plataforma svg {
  width: 60px;
  min-width: 60px;
  max-height: 15px;
  fill: var(--cor-branco-fixo);
}

.integracao .nome .plataforma.hotmart {
  background-color: #ef4e23;
}

.integracao .nome .plataforma.ticto {
  background-color: #000000;
}

.integracao .nome .plataforma.kiwify {
  background-color: #2fb56f;
}

.integracao .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 0 15px;
}

.integracao .opcoes {
  display: flex;
  align-items: center;
}

.integracao .opcoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  width: 40px;
  height: 40px;
  transition: all 0.3s;
}

.integracao .opcoes button:hover {
  background-color: var(--cor-laranja);
}

.integracao .opcoes button:hover p {
  color: var(--cor-branco-fixo);
}

.integracao .opcoes button:hover svg {
  fill: var(--cor-branco-fixo);
}

.integracao .opcoes button.editar {
  margin: 0 10px;
}

.integracao .opcoes button.entrar {
  width: 130px;
}

.integracao .opcoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.integracao .opcoes button:hover {
  border: 2px solid var(--cor-azul-forte);
}

.integracao .opcoes button svg {
  width: 13px;
  min-width: 13px;
  max-height: 13px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.integracao .dados {
  display: flex;
  align-items: center;
  justify-content: center;
}

.integracao .dados .dado {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 0;
}

.integracao .dados .dado.ativo p {
  color: var(--cor-branco);
}

.integracao .dados .dado.ativo span {
  background-color: var(--cor-verde);
}

.integracao .dados .dado span {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background-color: var(--cor-vermelho);
  margin: 0 5px 0 0;
}

.integracao .dados .dado:nth-child(1) {
  border-left: none;
}

.integracao .dados .dado svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-5);
  margin: 0 10px 0 0;
}

.integracao .dados .dado p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.3;
  text-align: center;
}

.integracao .dados .dado p span {
  font-size: var(--f2);
  margin: 0 5px 0 0;
}

@media screen and (max-width: 1000px) {
  .integracao {
    margin: 0 0 20px 0;
  }

  .integracao {
    flex-wrap: wrap;
  }

  .integracao .capa {
    max-width: 70px;
  }

  .integracao .nome {
    width: calc(100% - 70px);
  }

  .integracao .opcoes {
    width: 100%;
  }

  .integracao .dados {
    justify-content: flex-start;
    padding: 20px 0;
    margin: 0 15px 0 0;
    width: 100%;
  }

  .integracao .dados .dado p {
    font-size: var(--f0);
    line-height: 1.3;
  }

  .integracao .dados .dado p span {
    font-size: var(--f1);
    margin: 0 3px 0 0;
  }
}
</style>
