<template>
  <section class="opcoes">
    <div class="dominio" @click="router.push(`/areas/${storeAreas.area?.hotlink}/dominio`)">
      <div class="icone">
        <Svgs nome="dominio" />
      </div>
      <div class="nome">
        <h3>Domínio</h3>
        <p>Configure seu domínio personalizado</p>
      </div>
    </div>

    <a class="whatsapp" href="https://wa.me/551831993340" target="_blank">
      <div class="icone">
        <div class="svg">
          <Svgs nome="whatsapp" />
        </div>
        <img src="https://arquivos.levelmember.com/favicons/favicon.svg" />
        <span></span>
      </div>
      <div class="nome">
        <h3>Suporte Level</h3>
        <p>Precisa de Ajuda? Chame nosso time</p>
      </div>
    </a>
  </section>
</template>

<script setup>
import { useStoreAreas } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const router = useRouter()
const storeAreas = useStoreAreas()
</script>

<style scoped>
section.opcoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.whatsapp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: calc(60% - 20px);
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-2);
  border-radius: 10px;
  padding: 30px;
  position: relative;
  transition: all 0.3s;
}

.whatsapp:hover {
  border: 1px solid var(--cor-laranja);
}

.whatsapp .icone {
  display: flex;
  align-items: center;
  position: relative;
}

.whatsapp .icone .svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
}

.whatsapp .icone .svg svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-branco);
}

.whatsapp .icone img {
  width: 70px;
  min-width: 70px;
  border-radius: 10px;
  margin: 0 0 0 10px;
}

.whatsapp .icone span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--cor-verde);
  box-shadow: 0 0 15px 0 var(--cor-verde);
  position: absolute;
  top: 0;
  left: 0;
}

.whatsapp .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding: 0 0 0 10px;
  margin-right: auto;
}

.whatsapp .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.whatsapp .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.dominio {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-2);
  border-radius: 10px;
  padding: 30px;
  position: relative;
  transition: all 0.3s;
}

.dominio:hover {
  border: 1px solid var(--cor-laranja);
}

.dominio .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
  height: 60px;
  margin: 0 15px 0 0;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
}

.dominio .icone svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-branco);
}

.dominio .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.dominio .nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.dominio .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  width: 100%;
  max-width: 280px;
}

@media screen and (max-width: 1000px) {
  section.opcoes {
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }

  .whatsapp {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 30px 20px 30px 20px;
  }

  .whatsapp .icone {
    margin: 0;
  }

  .whatsapp .icone .svg svg {
    width: 20px;
    min-width: 20px;
  }

  .whatsapp .icone img {
    width: 60px;
    min-width: 60px;
  }

  .whatsapp .nome {
    align-items: center;
    text-align: center;
    padding: 25px 0 0 0;
    margin-right: 0;
  }

  .whatsapp .nome p {
    width: 100%;
  }

  .dominio {
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    margin: 0 0 20px 0;
  }

  .dominio .icone {
    width: 60px;
    height: 60px;
    max-width: 60px;
  }

  .dominio .icone svg {
    width: 20px;
    min-width: 20px;
  }

  .dominio .nome h3 {
    font-size: var(--f2);
    margin: 5px 0 5px 0;
  }

  .dominio .nome p {
    font-size: var(--f1);
    max-width: 100%;
    margin: 0 0 0 0;
  }
}
</style>
