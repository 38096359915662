export default [
  {
    path: '/entrar',
    redirect: '/'
  },
  {
    path: '/',
    name: 'entrar',
    component: () => import('../components/autenticacao/entrar/Entrar.vue'),
    meta: {
      logged: false,
      title: 'Entre em sua conta de Produtor | Level Member',
      layout: 'default-layout'
    }
  },
  {
    path: '/registrar',
    name: 'registrar',
    component: () => import('../components/autenticacao/registrar/Registrar.vue'),
    meta: {
      logged: false,
      title: 'Crie sua conta | Level Member',
      layout: 'default-layout'
    }
  },
  {
    path: '/boas-vindas',
    name: 'boasVindas',
    component: () => import('../components/autenticacao/boasVindas/BoasVindas.vue'),
    meta: {
      title: 'Boas-vindas á LevelMember',
      layout: 'default-layout'
    }
  },
  {
    path: '/assinatura-ativa',
    name: 'assinaturaAtiva',
    component: () => import('../components/autenticacao/assinaturaAtiva/AssinaturaAtiva.vue'),
    meta: {
      title: 'Boas-vindas á LevelMember',
      layout: 'default-layout'
    }
  }
]
