import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiComentarios from '../api/produtor/api-comentarios'

export const useStoreComentarios = defineStore('storeComentarios', {
  state: () => {
    return {
      comentarios: null
    }
  },
  actions: {
    async receberTodos(idArea, idCurso, idModulo, idAula) {
      try {
        const resp = await apiComentarios.receberTodos(idArea, idCurso, idModulo, idAula)
        this.comentarios = resp
        return resp
      } catch (error) {
        this.comentarios = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async deletar(idArea, idCurso, idModulo, idAula, idComentario) {
      try {
        const resp = await apiComentarios.deletar(idArea, idCurso, idModulo, idAula, idComentario)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async comentar(idArea, idCurso, idModulo, idAula, payload) {
      try {
        const resp = await apiComentarios.comentar(idArea, idCurso, idModulo, idAula, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async responder(idArea, idCurso, idModulo, idAula, idComentario, payload) {
      try {
        const resp = await apiComentarios.responder(idArea, idCurso, idModulo, idAula, idComentario, payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
