<template>
  <nav ref="navArea">
    <div class="itens" @mousedown="mouseDown($event, '.itens')" @mouseleave="mouseLeave('.itens')" @mousemove="mouseMove($event, '.itens')" @mouseup="mouseUp('.itens')" ref="itensNav">
      <div class="capa" @click="irParaArea()">
        <div class="nome">
          <img :src="storeAreas.area?.imagemLogo" />
          <h3>{{ storeAreas.area?.nome }}</h3>

          <p v-if="storeAreas.area?.dominioPersonalizado">{{ storeAreas.area?.dominioPersonalizado }}</p>
          <p v-else>mber.live/{{ storeAreas.area?.hotlink }}</p>
        </div>
      </div>

      <a @click="editarArea(storeAreas.area?._id)" class="item">
        <div class="icone">
          <Svgs nome="editar" />
        </div>
        <p>Editar Área</p>
      </a>

      <template v-for="(item, index) in menuItems" :key="index">
        <a :class="{ ativo: $route.path === item.path, breve: item.breve }" @click="abrirPagina(item.path)" :href="item.external ? item.path : null" target="_blank" class="item" v-if="!item.external">
          <div class="icone">
            <Svgs :nome="item.icon" />
          </div>
          <p>{{ item.label }}</p>
        </a>

        <a v-else :href="item.path" target="_blank" class="item">
          <div class="icone">
            <Svgs :nome="item.icon" />
          </div>
          <p>{{ item.label }}</p>
        </a>
      </template>
    </div>
  </nav>
  <ModalEditarArea />
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreAreas, useStoreModal } from '@stores'
import { helperDragScroll } from '@helpers'
import ModalEditarArea from '@components/app/areas/modals/EditarArea.vue'
import Svgs from '@svgs'

const router = useRouter()
const storeModal = useStoreModal()
const storeAreas = useStoreAreas()

const navArea = ref(null)
const itensNav = ref(null)

const menuItems = computed(() => {
  const hotlink = storeAreas.area?.hotlink || ''
  return [
    { path: `/areas/${hotlink}`, label: 'Dashboard', icon: 'template' },
    { path: `/areas/${hotlink}/personalizar`, label: 'Personalizar', icon: 'design' },
    { path: `/areas/${hotlink}/cursos`, label: 'Cursos', icon: 'curso' },
    { path: `/areas/${hotlink}/modulos`, label: 'Módulos', icon: 'modulo' },
    { path: `/areas/${hotlink}/aulas`, label: 'Aulas', icon: 'play' },
    { path: `/areas/${hotlink}/pagamentos`, label: 'Pagamentos', icon: 'dinheiro' },
    { path: `/areas/${hotlink}/integracoes`, label: 'Integrações', icon: 'integracao' },
    { path: `/areas/${hotlink}/notificacoes`, label: 'Notificações', icon: 'sino' },
    { path: `/areas/${hotlink}/alunos`, label: 'Alunos', icon: 'usuarios' },
    { path: `/areas/${hotlink}/dominio`, label: 'Domínio', icon: 'dominio' },
    { path: ``, label: 'Comunicados', icon: 'atencao', breve: true },
    { path: ``, label: 'Ranking', icon: 'ranking', breve: true },
    { path: ``, label: 'Chat I.A', icon: 'ia-normal', breve: true },
    { path: ``, label: 'Equipe', icon: 'equipe', breve: true }
  ]
})

function abrirPagina(pagina) {
  router.push(pagina)
}

function irParaArea() {
  const linkDominio = storeAreas.area?.dominioPersonalizado
  const linkHotlink = storeAreas.area?.hotlink

  const url = linkDominio ? `https://${linkDominio}` : `https://mber.live/${linkHotlink}`

  window.open(url, '_blank')
}

function editarArea(idArea) {
  storeModal.abrirModalEdicao('editarArea', 'editar', 'Editar Área', idArea)
}

async function carregarArea() {
  const hotlink = router.currentRoute.value.params.hotlinkArea

  await storeAreas.receberPorHotlink(hotlink)

  document.title = `${storeAreas.area?.nome} | Level Member`
}

function mouseDown(event, classe) {
  helperDragScroll.mouseDown(event, navArea.value, classe)
}

function mouseLeave(classe) {
  helperDragScroll.mouseLeave(navArea.value, classe)
}

function mouseUp(classe) {
  helperDragScroll.mouseUp(navArea.value, classe)
}

function mouseMove(event, classe) {
  helperDragScroll.mouseMove(event, navArea.value, classe)
}

watch(
  () => router.currentRoute.value.path,
  () => {
    if (itensNav.value) {
      itensNav.value.scrollLeft = 0
    }
  }
)

onMounted(() => {
  carregarArea()
})
</script>

<style scoped>
nav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  z-index: 8;
  position: relative;
}

.itens {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  user-select: none;
  cursor: grab;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  padding: 20px;
}

.itens::-webkit-scrollbar {
  display: none;
}

nav .capa {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 240px;
  min-width: 240px;
  height: 120px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

nav .capa:hover {
  background-color: var(--cor-cinza-3);
}

nav .capa .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
}

nav .capa .nome img {
  width: 100%;
  max-width: 100px;
  max-height: 30px;
}

nav .capa .nome h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 15px 0 0 0;
}

nav .capa .nome p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
}

nav .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 120px;
  min-width: 120px;
  height: 120px;
  border-radius: 10px;
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-2);
  margin: 0 0 0 15px;
  transition: all 0.3s;
}

nav .item.breve {
  pointer-events: none;
  background-color: var(--cor-cinza-1);
  border: 1px solid var(--cor-cinza-3);
}

nav .item.breve p {
  color: var(--cor-cinza-5);
}

nav .item.breve .icone svg {
  fill: var(--cor-cinza-5);
}

nav .item:hover {
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-cinza-3);
}

nav .item:hover .icone {
  background-color: var(--cor-cinza-2);
}

nav .item .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  margin: 0 0 10px 0;
  transition: all 0.3s;
}

nav .item .icone svg {
  width: 20px;
  min-width: 20px;
  max-height: 20px;
  fill: var(--cor-branco);
}

nav .item p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

nav .item.ativo {
  border: 1px solid var(--cor-laranja);
}

nav .item.ativo .icone {
  background-color: var(--cor-laranja);
}

nav .item.ativo .icone svg {
  fill: var(--cor-branco-fixo);
}

/* Responsivo */
@media screen and (max-width: 1000px) {
}
</style>
