<template>
  <div id="afiliados">
    <Loading :class="{ mostrar: state.verLoading }" />
    <SectionPlanos />
    <SectionDuvida />
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import Loading from '@components/global/loading/Loading.vue'
import SectionPlanos from '@components/app/afiliados/partials/SectionPlanos.vue'
import SectionDuvida from '@components/app/afiliados/partials/SectionDuvida.vue'

const state = reactive({
  verLoading: true
})

function carregarTela() {
  setTimeout(() => {
    state.verLoading = false
  }, 300)
}

onMounted(() => {
  carregarTela()
})
</script>

<style scoped>
#afiliados {
  animation: fadeIn 0.3s linear;
}
</style>
