<template>
  <section class="lista">
    <!-- Pixel Facebook -->
    <div class="item">
      <div class="icone">
        <Svgs nome="meta" />
      </div>
      <div class="nome">
        <h3>Pixel do Facebook</h3>
      </div>

      <div class="dados" v-if="storeAreas.area?.integracoes?.facebook?.id && storeAreas.area?.integracoes?.facebook?.evento">
        <div class="dado ativo">
          <Svgs nome="check" />
          <p>Pixel Ativo: {{ storeAreas.area?.integracoes?.facebook?.id }}</p>
        </div>
        <div class="dado ativo">
          <Svgs nome="check" />
          <p>Evento: {{ storeAreas.area?.integracoes?.facebook?.evento }}</p>
        </div>
      </div>
      <div class="dados" v-else>
        <div class="dado inativo">
          <Svgs nome="integracao" />
          <p>Configurar</p>
        </div>
      </div>
      <div class="opcoes">
        <button class="entrar" @click="abrirModalFacebook()">
          <p>Configurar</p>
          <Svgs nome="seta" />
        </button>
      </div>
    </div>
    <!-- Google Tag -->
    <div class="item">
      <div class="icone">
        <Svgs nome="google" />
      </div>
      <div class="nome">
        <h3>Google Tag Manager</h3>
      </div>

      <div class="dados">
        <div class="dado ativo" v-if="storeAreas.area?.integracoes?.tagManager?.id">
          <Svgs nome="check" />
          <p>Tag: {{ storeAreas.area?.integracoes?.tagManager?.id }}</p>
        </div>
        <div class="dado inativo" v-else>
          <Svgs nome="integracao" />
          <p>Configurar</p>
        </div>
      </div>
      <div class="opcoes">
        <button class="entrar" @click="abrirModalGoogleTag()">
          <p>Configurar</p>
          <Svgs nome="seta" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal, useStoreAreas } from '@stores'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeModal = useStoreModal()

function abrirModalFacebook() {
  storeModal.abrirModal('facebookMeta', 'meta', 'Pixel do Facebook')
}

function abrirModalGoogleTag() {
  storeModal.abrirModal('googleTag', 'google', 'Google Tag Manager')
}
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 20px 20px 20px;
  min-height: 100dvh;
}

.busca {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 20px 0;
}

.busca .input {
  width: calc(80% - 8px);
}

.busca .filtrar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(20% - 8px);
}

.busca .filtrar svg {
  position: absolute;
  right: 15px;
  width: 10px;
  min-width: 10px;
  fill: var(--cor-cinza-5);
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-cinza-1);
  border-top: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 20px 0;
}

.item:last-child {
  border-bottom: 1px solid var(--cor-cinza-3);
}

.item .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
}

.item .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}

.item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 15px;
}

.item .nome h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
}

.item .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
  line-height: 1.5;
}

.item .opcoes {
  display: flex;
  align-items: center;
}

.item .opcoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  width: 40px;
  height: 40px;
  border-radius: 10px;
  transition: all 0.3s;
}

.item .opcoes button.excluir {
  margin: 0 10px;
}

.item .opcoes button.senha {
  margin: 0 10px 0 0;
}

.item .opcoes button.entrar {
  justify-content: space-between;
  width: 140px;
  padding: 0 15px;
}

.item .opcoes button p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.item .opcoes button:hover {
  background-color: var(--cor-laranja);
}

.item .opcoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.item .dados {
  display: flex;
  align-items: center;
}

.item .dados .dado {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px 0 0;
}

.item .dados .dado:nth-child(1) {
  border-left: none;
}

.item .dados .dado.ativo svg {
  fill: var(--cor-verde);
}

.item .dados .dado.ativo p {
  color: var(--cor-branco);
}

.item .dados .dado.inativo svg {
  fill: var(--cor-cinza-4);
}

.item .dados .dado svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-cinza-5);
  margin: 0 5px 0 0;
}

.item .dados .dado p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.item .dados .dado p span {
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 5px 0 0;
}

@media screen and (max-width: 1000px) {
  section.items {
    flex-direction: column;
    width: 100%;
    padding: 0 20px 30px 20px;
  }

  .busca {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 20px 0;
  }

  .busca .input {
    width: calc(65% - 8px);
    margin: 0;
  }

  .busca .filtrar {
    width: calc(35% - 8px);
  }

  .item {
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
  }

  .item .capa {
    width: 100%;
    max-width: 50px;
  }

  .item .nome {
    margin: 0;
    width: calc(100% - 50px);
  }

  .item .opcoes {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
  }

  .item .opcoes button {
    margin: 0;
  }

  .item .dados {
    padding: 20px 0;
    width: 100%;
  }

  .item .dados .dado svg {
    width: 15px;
    min-width: 15px;
    max-height: 20px;
    margin: 0 5px 0 0;
  }

  .item .dados .dado p {
    font-size: var(--f0);
    line-height: 1.3;
  }

  .item .dados .dado p span {
    font-size: var(--f1);
    margin: 0 3px 0 0;
  }
}
</style>
