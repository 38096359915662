<template>
  <section class="topo">
    <div class="titulo">
      <div class="icone">
        <Svgs nome="dinheiro" />
      </div>
      <div class="nome">
        <h2>Gateways de Pagamento</h2>
        <p>{{ textoIntegracoes(storeIntegracoes.integracoes?.length) }} na área</p>
      </div>
    </div>
    <div class="opcoes">
      <button
        class="adicionar"
        @click="
          abrirModal(
            'criarIntegracao', // nome do modal
            'dinheiro', // nome do icone
            'Configurar Gateway' // titulo
          )
        ">
        <Svgs nome="mais" />
        <p>Nova configuração</p>
      </button>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal, useStoreIntegracoes } from '@stores'
import Svgs from '@svgs'

const storeIntegracoes = useStoreIntegracoes()
const storeModal = useStoreModal()

function abrirModal(nome, icone, titulo) {
  storeModal.abrirModal(nome, icone, titulo)
}

const textoIntegracoes = (quantidade) => {
  return `${quantidade} ${quantidade === 1 ? 'gateway' : 'gateways'}`
}
</script>

<style scoped>
section.topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 20px 20px;
}

.titulo {
  display: flex;
  align-items: center;
}

.titulo .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--cor-cinza-2);
  margin: 0 15px 0 0;
}

.titulo .icone svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
}

.titulo .nome h2 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.titulo .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
}

.opcoes {
  display: flex;
}

.opcoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  border-radius: 10px;
  background-color: var(--cor-laranja);
  margin: 0 0 0 15px;
  transition: all 0.3s;
}

.opcoes button:hover {
  background-color: var(--cor-laranja-escuro);
}

.opcoes button.posicao {
  background-color: var(--cor-cinza-2);
}

.opcoes button.posicao:hover {
  background-color: var(--cor-cinza-3);
}

.opcoes button.posicao svg {
  fill: var(--cor-branco);
}

.opcoes button.posicao p {
  color: var(--cor-branco);
}

.opcoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}

.opcoes button p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
}

@media screen and (max-width: 1000px) {
  section.topo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 20px 20px 20px;
  }

  .titulo {
    display: flex;
    align-items: center;
  }

  .titulo .icone {
    width: 45px;
    height: 45px;
    margin: 0 10px 0 0;
  }

  .titulo .icone svg {
    width: 15px;
    min-width: 15px;
  }

  .titulo .nome h2 {
    font-size: var(--f2);
  }

  .titulo .nome p {
    font-size: var(--f1);
    margin: 5px 0 0 0;
  }

  .opcoes {
    width: 100%;
    justify-content: space-between;
    padding: 20px 0 0 0;
  }

  .opcoes button.adicionar {
    width: calc(50% - 8px);
    padding: 13px 20px;
    margin: 0;
  }

  .opcoes button p {
    font-size: var(--f1);
  }

  .opcoes button.adicionar svg {
    margin: 0 8px 0 0;
    width: 13px;
    min-width: 13px;
  }

  .opcoes button.posicao {
    margin: 0;
    width: calc(50% - 8px);
  }
}
</style>
