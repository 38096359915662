<template>
  <Modal nome="criarAula">
    <label class="um">
      Nome da aula
      <span>*</span>
    </label>
    <input v-model="state.nome" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Aula de Marketing" @keydown.enter="criarAula" />

    <label>Descrição da aula</label>
    <textarea v-model="state.descricao" type="text" spellCheck="false" autocomplete="false" placeholder="Ex: Aula abordando o tema marketing" v-redimensionar-textarea></textarea>

    <label>Duração da aula em minutos</label>
    <input v-model="state.duracao" type="number" spellCheck="false" autocomplete="false" placeholder="0" />

    <label>
      URL de incorporação do vídeo
      <span>*</span>
    </label>
    <textarea v-model="conteudoTextarea" @input="filtrarInput" type="text" spellCheck="false" autocomplete="false" placeholder="https://video.com/embed/link.mp4" v-redimensionar-textarea></textarea>

    <div class="video" v-if="codigoIframe">
      <div class="tamanho">
        <iframe :src="codigoIframe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
      </div>
    </div>

    <label>
      Imagem de capa da aula
      <span>*</span>
    </label>
    <UploadArquivo :valor="state.imagemCapa" :onAtualizarImagem="atualizarImagemCapa" :defaultSize="'1280x720'" :uploadText="'Subir Capa'" :maxWidth="1280" />

    <label>Links da aula</label>
    <div class="links">
      <div class="link" v-for="(link, index) in state.links" :key="index">
        <input v-model="link.nome" type="text" placeholder="Nome do link" />
        <input v-model="link.url" type="text" placeholder="https://link.com" />
        <button class="excluir" @click="removerLink(index)">
          <Svgs nome="excluir" />
        </button>
      </div>
      <button class="adicionar" @click="adicionarLink">
        <Svgs nome="mais" />
        <p>Adicionar novo link</p>
      </button>
    </div>

    <label>Arquivos da aula</label>
    <div class="arquivos">
      <div class="arquivo" v-for="(arquivo, index) in state.arquivos" :key="index">
        <input v-model="arquivo.nome" type="text" placeholder="Nome do arquivo" />
        <div
          class="subir"
          :class="{
            carregar: arquivo.loading,
            carregado: arquivo.url
          }"
          @click="abrirInputFile(index)">
          <span></span>
          <p v-if="arquivo.peso">{{ arquivo.peso }}</p>
          <h3>{{ nomeArquivo(arquivo.url) || 'Subir Arquivo' }}</h3>
          <input type="file" hidden :ref="(el) => (arquivo.inputRef = el)" @change="(event) => selecionarImagem(event, index)" />
        </div>
        <button class="excluir" @click="removerArquivo(index)">
          <Svgs nome="excluir" />
        </button>
      </div>
      <button class="adicionar" @click="adicionarArquivo">
        <Svgs nome="mais" />
        <p>Adicionar novo arquivo</p>
      </button>
    </div>

    <label>
      Módulo da aula
      <span>*</span>
    </label>
    <div class="select">
      <Svgs nome="setinha" />
      <select v-model="state.idModulo">
        <option v-for="(modulo, index) in storeModulos.modulos" :key="index" :value="modulo._id">Módulo {{ modulo.nome }}</option>
      </select>
    </div>

    <SalvarModal nome="Criar Nova Aula" @click="criarAula()" />
  </Modal>
</template>

<script setup>
import { inject, onMounted, reactive, computed, watch, ref, nextTick } from 'vue'
import { useStoreAreas, useStoreCursos, useStoreModal, useStoreModulos, useStoreAulas } from '@stores'
import { helperTexto } from '@helpers'

import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import UploadArquivo from '@components/global/elementos/UploadArquivo.vue'
import Svgs from '@svgs'
import ApiUpload from '@/api/upload/api-upload.js'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeAulas = useStoreAulas()
const storeCursos = useStoreCursos()
const storeModal = useStoreModal()
const storeModulos = useStoreModulos()

const dadosModal = computed(() => storeModal.dadosDoModal('criarAula'))

const conteudoTextarea = ref('')
const codigoIframe = ref('')

const state = reactive({
  nome: '',
  descricao: '',
  hotlink: '',
  imagemCapa: '',
  urlVideo: '',
  duracao: 0,
  idModulo: '',
  links: [],
  arquivos: []
})

function atualizarImagemCapa(novaUrl) {
  state.imagemCapa = novaUrl
}

function nomeParaHotlink() {
  state.hotlink = helperTexto.converterEmHotlink(state.nome)
}

function adicionarLink() {
  state.links.push({ nome: '', url: '' })
}

function removerLink(index) {
  state.links.splice(index, 1)
}

function adicionarArquivo() {
  state.arquivos.push({ nome: '', url: '', peso: '', inputRef: null, loading: false })
}

function removerArquivo(index) {
  state.arquivos.splice(index, 1)
}

function abrirInputFile(index) {
  if (state.arquivos[index].inputRef) state.arquivos[index].inputRef.click()
}

function selecionarImagem(event, index) {
  const arquivoSelecionado = event.target.files[0]
  if (!arquivoSelecionado) return

  state.arquivos[index].loading = true

  ApiUpload.uploadArquivo({ arquivo: arquivoSelecionado })
    .then((resp) => {
      state.arquivos[index].loading = false
      state.arquivos[index].url = resp.url
      state.arquivos[index].peso = resp.peso
    })
    .catch(() => {
      state.arquivos[index].loading = false
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao subir arquivo' })
    })
}

function nomeArquivo(arquivoUrl) {
  if (!arquivoUrl) return ''
  const semArquivos = arquivoUrl.split('/arquivos/')[1] || ''
  const partes = semArquivos.split('-')
  return partes.slice(1).join('-')
}

async function criarAula() {
  if (!validarDados()) return

  nomeParaHotlink()

  const idArea = storeAreas.area?._id
  const idCurso = storeCursos.curso?._id
  const idModulo = state.idModulo

  const payload = {
    nome: state.nome,
    descricao: state.descricao,
    hotlink: state.hotlink,
    imagemCapa: state.imagemCapa,
    duracao: state.duracao,
    urlVideo: state.urlVideo,
    links: state.links,
    arquivos: state.arquivos
  }

  const adicionadoOk = await storeAulas.adicionar(idArea, idCurso, idModulo, payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarAulas')
  storeModal.fecharModal('criarAula')
  limparCampos()
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.descricao || !state.imagemCapa) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

const filtrarInput = async () => {
  const pegarIframe = conteudoTextarea.value.match(/<iframe.*?src="(.*?)"/)
  const pegarUrl = conteudoTextarea.value.match(/https?:\/\/[^\s]+/)

  if (pegarIframe) {
    codigoIframe.value = pegarIframe[1]
    state.urlVideo = codigoIframe.value
  } else if (pegarUrl) {
    codigoIframe.value = pegarUrl[0]
    state.urlVideo = codigoIframe.value
  } else {
    codigoIframe.value = ''
    state.urlVideo = ''
  }

  if (state.urlVideo.includes('youtube.com') || state.urlVideo.includes('youtu.be')) {
    const videoIdMatch = state.urlVideo.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/)
    if (videoIdMatch) {
      const videoId = videoIdMatch[1]
      state.imagemCapa = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
      state.urlVideo = `https://www.youtube.com/embed/${videoId}`
      codigoIframe.value = state.urlVideo
    }
  }

  if (state.urlVideo.includes('pandavideo.com.br')) {
    const videoIdMatch = state.urlVideo.match(/\/v=([a-z0-9-]+)/i) || state.urlVideo.match(/\/embed\/\?v=([a-z0-9-]+)/i)
    if (videoIdMatch) {
      const videoId = videoIdMatch[1]
      const servidorId = 'b-vz-cce5a623-141'
      state.imagemCapa = `https://${servidorId}.tv.pandavideo.com.br/${videoId}/thumbnail.jpg`
    }
  }

  if (state.urlVideo.includes('vimeo.com')) {
    const videoIdMatch = state.urlVideo.match(/video\/(\d+)/)
    if (videoIdMatch) {
      const videoId = videoIdMatch[1]
      try {
        const response = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`)
        const data = await response.json()
        state.imagemCapa = data[0].thumbnail_large
      } catch {}
    }
  }
}

function limparCampos() {
  state.nome = ''
  state.descricao = ''
  state.hotlink = ''
  state.imagemCapa = ''
  state.duracao = 0
  state.urlVideo = ''
  state.idModulo = ''
  state.links = []
  state.arquivos = []
  codigoIframe.value = ''
  conteudoTextarea.value = ''
}

async function pegarId() {
  if (dadosModal.value.id) {
    state.idModulo = dadosModal.value.id
  }
}

onMounted(() => {
  watch(
    dadosModal,
    (novoValor) => {
      if (novoValor.id) {
        pegarId()
      }
    },
    { immediate: true }
  )
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

label.um {
  margin: 0 0 10px 0;
}

label.link {
  display: flex;
  align-items: center;
  width: 100%;
}

label button {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-azul);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  opacity: 0.6;
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-roxo);
  margin: 0 5px 0 0;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.links .link {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin: 0 0 15px 0;
}

.links .link input:nth-child(1) {
  width: calc(40% - 30px);
}

.links .link input:nth-child(2) {
  width: calc(60% - 40px);
}

.links .link button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  width: 50px;
  border-radius: 10px;
}

.links .link button:hover svg {
  fill: var(--cor-vermelho);
}

.links .link button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.links button.adicionar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  width: 100%;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}

.links button.adicionar:hover {
  background-color: var(--cor-cinza-4);
}

.links button.adicionar p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.links button.adicionar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.arquivos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.arquivos .arquivo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin: 0 0 15px 0;
}

.arquivos .arquivo input:nth-child(1) {
  width: calc(40% - 30px);
}

.arquivos .arquivo .subir {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 15px;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: calc(60% - 40px);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
}

.arquivos .arquivo .subir:hover {
  background-color: var(--cor-cinza-4);
}

.arquivos .arquivo .subir.carregar span {
  opacity: 1;
  visibility: visible;
}

.arquivos .arquivo .subir.carregar h3 {
  opacity: 0;
}

.arquivos .arquivo .subir span {
  opacity: 0;
  visibility: hidden;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid var(--cor-branco);
  border-top: 3px solid transparent;
  position: absolute;
  left: 15px;
  pointer-events: none;
  animation: girando 0.5s linear infinite;
  transition: all 0.3s;
}

.arquivos .arquivo .subir p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.arquivos .arquivo .subir.carregado p {
  color: var(--cor-branco);
}

.arquivos .arquivo .subir h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  word-break: keep-all;
  transition: all 0.3s;
}

.arquivos .arquivo .subir.carregado h3 {
  color: var(--cor-branco);
}

.arquivos .arquivo button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  width: 50px;
  border-radius: 10px;
}

.arquivos .arquivo button:hover svg {
  fill: var(--cor-vermelho);
}

.arquivos .arquivo button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.arquivos button.adicionar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  width: 100%;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}

.arquivos button.adicionar:hover {
  background-color: var(--cor-cinza-4);
}

.arquivos button.adicionar p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.arquivos button.adicionar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.video {
  width: 100%;
  margin: 20px 0 0 0;
}

.video .tamanho {
  position: relative;
  padding-bottom: 56.52%;
  width: 100%;
}

.video .tamanho iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  background-color: var(--cor-preto-fixo);
}

button.salvar {
  margin: 20px 0 0 0;
}
</style>
