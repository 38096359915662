<template>
  <div id="areas">
    <Loading :class="{ mostrar: state.verLoading }" />
    <ModalCriarArea />
    <ModalExcluirArea />
    <ModalDuplicarArea />

    <BalaoSuporte />
    <SectionLimites />
    <SectionAreas />
  </div>
</template>

<script setup>
import { onMounted, inject, reactive } from 'vue'
import { useStoreAreas } from '@stores'

import Loading from '@components/global/loading/Loading.vue'
import SectionLimites from '@components/app/areas/partials/SectionLimites.vue'
import SectionAreas from '@components/app/areas/partials/SectionAreas.vue'
import BalaoSuporte from '@components/app/areas/partials/BalaoSuporte.vue'
import ModalExcluirArea from '@components/app/areas/modals/ExcluirArea.vue'
import ModalCriarArea from '@components/app/areas/modals/CriarArea.vue'
import ModalDuplicarArea from '@components/app/areas/modals/DuplicarArea.vue'

const state = reactive({
  verLoading: true
})

const storeAreas = useStoreAreas()
const emitter = inject('emitter')

async function carregarAreas() {
  await storeAreas.receberTodos()
  setTimeout(() => {
    state.verLoading = false
  }, 300)
}

onMounted(() => {
  carregarAreas()
  emitter.on('atualizarAreas', carregarAreas)
})
</script>

<style scoped>
#areas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
