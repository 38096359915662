<template>
  <Modal nome="facebookMeta">
    <label class="um">
      Script Pixel do Facebook &lt;head&gt;
      <span>*</span>
    </label>
    <textarea placeholder="Cole aqui o código script..." v-model="state.scriptCompleto" spellcheck="false" autocomplete="false" v-redimensionar-textarea></textarea>

    <BotaoSalvar nome="Salvar Pixel Facebook" @click="salvarPixelFacebook" />
  </Modal>
</template>

<script setup>
import { reactive, inject, onMounted } from 'vue'
import { useStoreAreas, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeModal = useStoreModal()

const state = reactive({
  id: '',
  evento: '',
  scriptCompleto: ''
})

function salvarPixelFacebook() {
  extrairPixel()

  const idArea = storeAreas.area._id

  const payload = {
    integracoes: {
      facebook: {
        id: state.id,
        evento: state.evento || 'pageview'
      }
    }
  }

  storeAreas.alterarDados(idArea, payload)

  setTimeout(() => {
    storeModal.fecharModal('facebookMeta')
    window.location.reload()
  }, 800)
}

function gerarCodigo(id, evento) {
  state.scriptCompleto = `
 <!-- Meta Pixel Code -->
&lt;script&gt;
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${id}');
fbq('track', '${evento}');
&lt;/script&gt;
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=${id}&ev=${evento}&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->
  `
}

function extrairPixel() {
  const regexInit = /fbq\('init', '([^']+)'\)/
  const regexTrack = /fbq\('track', '([^']+)'\)/

  const initMatch = state.scriptCompleto.match(regexInit)
  const trackMatch = state.scriptCompleto.match(regexTrack)

  state.id = initMatch ? initMatch[1] : ''
  state.evento = trackMatch ? trackMatch[1] : ''
}

function carregarDados() {
  state.id = storeAreas.area?.integracoes?.facebook?.id || ''
  state.evento = storeAreas.area?.integracoes?.facebook?.evento || ''
  if (state.id && state.evento) {
    gerarCodigo(state.id, state.evento)
  }
}

function limparCampos() {
  state.id = ''
  state.evento = ''
  state.scriptCompleto = ''
}

onMounted(() => {
  emitter.on('carregarCampos', carregarDados)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label {
  position: relative;
  width: 100%;
}

label.um {
  margin: 0 0 10px 0;
}

button {
  margin: 20px 0 0 0;
}

a {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s;
}

a:hover {
  opacity: 0.6;
}

a svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
}
</style>
