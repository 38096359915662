<template>
  <section class="etapas">
    <Particles colorTheme="free" />
    <div class="conteudo">
      <div class="titulo">
        <Svgs nome="logo-flat" />
        <h1>Parabéns {{ (storePerfil.usuario?.nome || '').split(' ')[0] }}, sua assinatura está ativa 🎉</h1>
        <p>Agora você tem acesso total a todos os recursos da LevelMember.</p>
      </div>
      <div class="quadros">
        <div class="quadro">
          <div class="icone">
            <Svgs nome="discord" />
            <span></span>
          </div>
          <h3>Participe da Comunidade</h3>
          <p>Você tem direito a cargos maiores em nossa comunidade com sua assinatura Level. Entre agora mesmo para liberar!</p>
          <button :class="{ feito: state.um }" @click="abrirUm()">
            Entrar na comunidade
            <Svgs :nome="state.um ? 'check' : 'seta'" />
          </button>
        </div>

        <div class="quadro">
          <div class="icone">
            <Svgs nome="whatsapp" />
            <span></span>
          </div>
          <h3>Suporte via WhatsApp</h3>
          <p>Agora com sua assinatura Level, caso tenha qualquer dúvida o nosso time de suporte está disponível 24h.</p>
          <button :class="{ feito: state.dois }" @click="abrirDois()">
            Abrir WhatsApp
            <Svgs :nome="state.dois ? 'check' : 'seta'" />
          </button>
        </div>

        <div class="quadro">
          <div class="icone">
            <Svgs nome="check" />
            <span></span>
          </div>
          <h3>Acessar plataforma</h3>
          <p>Aproveite sua assinatura para criar áreas ainda mais completas com todo arsenal tecnológico em mãos.</p>
          <button :class="{ feito: state.tres }" @click="abrirTres()">
            Entrar agora
            <Svgs :nome="state.tres ? 'check' : 'seta'" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue'
import { useStorePerfil } from '@stores'
import Particles from '@components/global/particles/Particles.vue'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()

const state = reactive({
  um: false,
  dois: false,
  tres: false
})

function abrirUm() {
  state.um = true
  abrirPopup('https://discord.gg/GjqvZMsV9r')
}

function abrirDois() {
  state.dois = true
  abrirPopup('https://wa.me/551831993895')
}

function abrirTres() {
  state.quatro = true
  window.location.href = '/areas'
}

function abrirPopup(url) {
  const largura = 704
  const altura = 396
  const esquerda = (window.screen.width - largura) / 2
  const topo = (window.screen.height - altura) / 2
  window.open(url, '_blank', `width=${largura},height=${altura},top=${topo},left=${esquerda},resizable=no,scrollbars=no,status=no`)
}
</script>

<style scoped>
section.etapas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: var(--cor-cinza-1);
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 3;
  backdrop-filter: blur(10px);
  min-height: 100dvh;
}

.titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.titulo svg {
  width: 200px;
  min-width: 200px;
}

.titulo h1 {
  font-family: var(--bold);
  font-size: var(--f5);
  color: var(--cor-branco);
  margin: 20px 0 10px 0;
  width: 100%;
}

.titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  width: 100%;
  line-height: 1.5;
}

.quadros {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px 0 0 0;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  padding: 40px 0;
  width: 100%;
  max-width: 250px;
  margin: 0 25px;
}

.quadro .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.quadro .icone svg {
  width: 40px;
  min-width: 40px;
  fill: var(--cor-verde);
}

.quadro .icone span {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  background: radial-gradient(circle, #1cae7a, #1cae7a00 60%);
  opacity: 0.2;
  animation: onda 2.5s infinite ease-in-out;
}

.quadro:nth-child(1) .icone span {
  animation-delay: 0s;
}

.quadro:nth-child(2) .icone span {
  animation-delay: 0.5s;
}

.quadro:nth-child(3) .icone span {
  animation-delay: 1s;
}

.quadro:nth-child(4) .icone span {
  animation-delay: 1.5s;
}

@keyframes onda {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.2;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.4;
  }
}

.quadro h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 25px 0 8px 0;
}

.quadro p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.quadro button {
  display: flex;
  align-items: center;
  justify-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-verde);
  padding: 8px 13px;
  border-radius: 5px;
  margin: 15px 0 0 0;
  transition: all 0.3s;
}

.quadro button.feito {
  background-color: var(--cor-verde);
}

.quadro button.feito:hover {
  background-color: var(--cor-verde);
}

.quadro button:hover {
  background-color: var(--cor-azul-escuro);
}

.quadro button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco-fixo);
  margin: 0 0 0 6px;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .conteudo {
    align-items: center;
    justify-content: flex-start;
    padding: 40px 0 80px 0;
  }

  .titulo {
    padding: 0 20px;
  }

  .titulo svg {
    width: 100px;
    min-width: 100px;
  }

  .titulo h1 {
    font-size: var(--f4);
    max-width: 280px;
    line-height: 1.3;
  }

  .titulo p {
    max-width: 270px;
  }

  .quadros {
    flex-direction: column;
    width: 100%;
    padding: 30px 0 0 0;
  }

  .quadro {
    padding: 30px 0;
    margin: 0;
  }
}
</style>
