<template>
  <div id="aulas">
    <Loading :class="{ mostrar: state.verLoading }" />
    <SectionAulas />
  </div>
</template>

<script setup>
import { inject, onMounted, reactive, watch } from 'vue'
import { useStoreAreas } from '@stores'

import Loading from '@components/global/loading/LoadingArea.vue'

import SectionAulas from '@components/areaDeMembros/aula/partials/SectionAula.vue'

const storeAreas = useStoreAreas()
const emitter = inject('emitter')

const state = reactive({
  verLoading: true
})

async function carregarAula() {
  state.verLoading = true

  setTimeout(() => {
    state.verLoading = false
  }, 300)
}

watch(
  () => [storeAreas.area?._id],
  ([idArea]) => {
    if (idArea) {
      carregarAula()
    }
  },
  { immediate: true }
)

onMounted(() => {
  emitter.on('atualizarAulas', carregarAula)
})
</script>

<style scoped>
#aulas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
