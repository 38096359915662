<template>
  <div id="integracoes">
    <Loading :class="{ mostrar: state.verLoading }" />

    <ModalCriarIntegracao />
    <ModalEditarIntegracao />
    <ModalEditarWebhook />
    <ModalExcluirIntegracao />

    <SectionTopo />
    <SectionIntegracoes />
    <SectionPlataformas />
  </div>
</template>

<script setup>
import { reactive, watch, inject, onMounted } from 'vue'
import { useStoreAreas, useStoreIntegracoes } from '@stores'

import Loading from '@components/global/loading/LoadingArea.vue'
import ModalCriarIntegracao from '@components/areaDeMembros/pagamentos/modals/CriarIntegracao.vue'
import ModalEditarIntegracao from '@components/areaDeMembros/pagamentos/modals/EditarIntegracao.vue'
import ModalEditarWebhook from '@components/areaDeMembros/pagamentos/modals/EditarWebhook.vue'
import ModalExcluirIntegracao from '@components/areaDeMembros/pagamentos/modals/ExcluirIntegracao.vue'

import SectionIntegracoes from '@components/areaDeMembros/pagamentos/partials/SectionIntegracoes.vue'
import SectionTopo from '@components/areaDeMembros/pagamentos/partials/SectionTopo.vue'
import SectionPlataformas from '@components/areaDeMembros/pagamentos/partials/SectionPlataformas.vue'

const storeIntegracoes = useStoreIntegracoes()
const storeAreas = useStoreAreas()
const emitter = inject('emitter')

const state = reactive({
  verLoading: false
})

async function carregarIntegracoes() {
  const idArea = storeAreas.area?._id
  if (idArea) {
    await storeIntegracoes.receberTodos(idArea)
    setTimeout(() => {
      state.verLoading = false
    }, 300)
  }
}

watch(
  () => storeAreas.area?._id,
  (idArea) => {
    if (idArea) {
      carregarIntegracoes()
    }
  },
  { immediate: true }
)

onMounted(() => {
  emitter.on('atualizarIntegracoes', carregarIntegracoes)
})
</script>

<style scoped>
#integracoes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
