<template>
  <div id="planos">
    <Loading :class="{ mostrar: state.verLoading }" />
    <SectionAtual />
    <SectionPlanos />
    <SectionContato />
    <SectionComparar />
    <SectionDuvida />
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import { useStorePerfil } from '@stores'
import Loading from '@components/global/loading/Loading.vue'
import SectionAtual from '@components/app/planos/partials/SectionAtual.vue'
import SectionPlanos from '@components/app/planos/partials/SectionPlanos.vue'
import SectionComparar from '@components/app/planos/partials/SectionComparar.vue'
import SectionContato from '@components/app/planos/partials/SectionContato.vue'
import SectionDuvida from '@components/app/planos/partials/SectionDuvida.vue'

const storePerfil = useStorePerfil()

const state = reactive({
  verLoading: true
})

async function carregarPerfil() {
  await storePerfil.receberPerfil()
  setTimeout(() => {
    state.verLoading = false
  }, 300)
}

onMounted(() => {
  carregarPerfil()
})
</script>

<style scoped>
#planos {
  animation: fadeIn 0.3s linear;
}
</style>
