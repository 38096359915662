<template>
  <section class="dados">
    <div class="quadro" @click="router.push(`/areas/${storeAreas.area?.hotlink}/alunos`)">
      <div class="icone">
        <Svgs nome="usuarios" />
      </div>
      <div class="nome">
        <h3>{{ quantidadeAlunos }}</h3>
        <p>Alunos nessa área de membros</p>
      </div>
    </div>

    <div class="quadro" @click="router.push(`/areas/${storeAreas.area?.hotlink}/pagamentos`)">
      <div class="icone">
        <Svgs nome="dinheiro" />
      </div>
      <div class="nome">
        <h3>{{ quantidadeIntegracoes }}</h3>
        <p>Configuração de pagamento nessa área</p>
      </div>
    </div>

    <div class="quadro" @click="router.push(`/areas/${storeAreas.area?.hotlink}/notificacoes`)">
      <div class="icone">
        <Svgs nome="sino" />
      </div>
      <div class="nome">
        <h3>{{ quantidadeNotificacoes }}</h3>
        <p>Notificações nessa área</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreAreas } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const router = useRouter()

const quantidadeAlunos = computed(() => {
  const quantidade = storeAreas.area?.quantidadeAlunos || 0
  return `${quantidade} ${quantidade === 1 ? 'aluno' : 'alunos'}`
})

const quantidadeIntegracoes = computed(() => {
  const quantidade = storeAreas.area?.quantidadeIntegracoes || 0
  return `${quantidade} ${quantidade === 1 ? 'configurado' : 'configurados'}`
})

const quantidadeNotificacoes = computed(() => {
  const quantidade = storeAreas.area?.quantidadeNotificacoesNaoLidas || 0
  return `${quantidade} ${quantidade === 1 ? 'notificação não lida' : 'notificações não lidas'}`
})
</script>

<style scoped>
section.dados {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 0 20px;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: calc(33.33% - 11px);
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-cinza-2);
  border-radius: 10px;
  padding: 40px;
  position: relative;
  transition: all 0.3s;
}

.quadro:hover {
  border: 1px solid var(--cor-laranja);
}

.quadro .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
}

.quadro .icone svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-branco);
}

.quadro .nome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.quadro .nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 20px 0 5px 0;
}

.quadro .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  section.dados {
    flex-direction: column;
    padding: 0 20px 0 20px;
  }

  .quadro {
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 10px;
    padding: 20px 20px;
    width: 100%;
    margin: 0 0 20px 0;
  }

  .quadro .icone {
    width: 60px;
    height: 60px;
    max-width: 60px;
  }

  .quadro .icone svg {
    width: 25px;
    min-width: 25px;
  }

  .quadro .nome {
    align-items: flex-start;
    text-align: left;
    padding: 0 0 0 15px;
  }

  .quadro .nome h3 {
    font-size: var(--f2);
    margin: 0 0 5px 0;
  }

  .quadro .nome p {
    font-size: var(--f1);
    max-width: 300px;
  }
}
</style>
