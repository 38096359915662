<template>
  <section class="plataformas">
    <div class="itens">
      <div class="item" v-for="opcao in state.opcoes" :key="opcao">
        <div class="capa" :style="`background-color: ${opcao.cor}`">
          <Svgs :nome="`logo-${opcao.nome}`" />
        </div>
        <div class="opcoes">
          <button class="integrar" @click="abrirModalEdicao('criarIntegracao', opcao.nome, `Configurar com ${opcao.nome}`, opcao.nome)" v-if="opcao.ativo">
            <p>Configurar</p>
          </button>
          <a class="integrar ativo breve" href="https://wa.me/551831993340" target="_blank" v-else>
            <p>Solicitar integração</p>
          </a>
          <!-- <button class="duvida" v-if="opcao.ativo">
            <Svgs nome="duvida" />
            <p>Como configurar?</p>
          </button> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreModal } from '@stores'
import { reactive } from 'vue'
import Svgs from '@svgs'

const storeModal = useStoreModal()

function abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id) {
  storeModal.abrirModalEdicao(nome, cor, icone, titulo, subtitulo, id)
}

const state = reactive({
  opcoes: [
    {
      nome: 'hotmart',
      cor: '#EF4E23',
      ativo: true
    },
    {
      nome: 'kiwify',
      cor: '#2FB56F',
      ativo: true
    },
    {
      nome: 'ticto',
      cor: '#000000',
      ativo: true
    },
    {
      nome: 'eduzz',
      cor: '#FFBC00',
      ativo: false
    },
    {
      nome: 'monetizze',
      cor: '#0030FF',
      ativo: false
    },
    {
      nome: 'digitalguru',
      cor: '#27B495',
      ativo: false
    },
    {
      nome: 'pagarme',
      cor: '#65A300',
      ativo: false
    },
    {
      nome: 'stripe',
      cor: '#635BFF',
      ativo: false
    },
    {
      nome: 'kirvano',
      cor: '#171717',
      ativo: false
    },
    {
      nome: 'mercadopago',
      cor: '#019EE3',
      ativo: false
    },
    {
      nome: 'herospark',
      cor: '#FF2F5C',
      ativo: false
    },
    {
      nome: 'braip',
      cor: '#6D36FA',
      ativo: false
    },
    {
      nome: 'asaas',
      cor: '#0030B9',
      ativo: false
    },
    {
      nome: 'xgrow',
      cor: '#73AE24',
      ativo: false
    },
    {
      nome: 'hubla',
      cor: '#314000',
      ativo: false
    }
  ]
})
</script>

<style scoped>
section.plataformas {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  min-height: 100dvh;
}

.itens {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.itens .item {
  display: flex;
  width: calc(33.33% - 10px);
  margin: 0 0 20px 0;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
}

.itens .item .capa {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 80px;
  border-radius: 10px 0 0 10px;
}

.itens .item .capa svg {
  width: 80px;
  max-width: 80px;
  max-height: 30px;
  fill: var(--cor-branco-fixo);
}

.itens .item .opcoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.itens .item .opcoes button.duvida {
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 0 0 0 10px;
}

.itens .item .opcoes button.duvida:hover p {
  color: var(--cor-branco);
}

.itens .item .opcoes button.duvida p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}

.itens .item .opcoes button.duvida:hover svg {
  fill: var(--cor-branco);
}

.itens .item .opcoes button.duvida svg {
  width: 13px;
  min-width: 13px;
  fill: var(--cor-cinza-5);
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

.itens .item .opcoes .integrar.breve {
  background-color: var(--cor-cinza-3);
}

.itens .item .opcoes .integrar.breve:hover {
  background-color: var(--cor-cinza-4);
}

.itens .item .opcoes .integrar {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: var(--cor-laranja);
  transition: all 0.3s;
}

.itens .item .opcoes .integrar:hover {
  background-color: var(--cor-laranja-escuro);
}

.itens .item .opcoes .integrar p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
}

.itens .item .opcoes .integrar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  margin: 0 5px 0 0;
}

@media screen and (max-width: 1000px) {
  section.plataformas {
    padding: 0 20px 0 20px;
  }

  .itens .item {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .itens .item .capa {
    width: 180px;
    height: 70px;
  }

  .itens .item .capa svg {
    width: 60px;
    max-width: 60px;
    max-height: 20px;
  }

  .itens .item .opcoes button.duvida p {
    font-size: var(--f0);
  }

  .itens .item .opcoes button.duvida svg {
    width: 10px;
    min-width: 10px;
  }
}
</style>
