<template>
  <section class="tela" ref="tela" @mouseover="removeClass">
    <div class="botoes">
      <button class="botao" @click="zoomIn()">
        <Svgs nome="zoom-in" />
        <p>Aumentar</p>
      </button>
      <button class="botao" @click="zoomOut()" :disabled="state.botaoZoomOut">
        <Svgs nome="zoom-out" />
        <p>Diminuir</p>
      </button>
      <button class="botao" @click="centralizarTela()">
        <Svgs nome="centralizar" />
        <p>Centralizar</p>
      </button>
      <button class="botao" @click="alterarTema()">
        <Svgs nome="tema" />
        <p>Modo {{ textoTema() }}</p>
      </button>
    </div>

    <div class="avisoMobile">
      <div class="icone">
        <Svgs nome="desktop" />
      </div>
      <p>Edite sua área acessando pelo computador ou notebook</p>
    </div>

    <div class="base">
      <VueDraggableResizable class="cor" :x="0" :y="0" :w="1" :h="1">
        <div class="sites">
          <div class="conteudo" :style="{ transform: `scale(${zoomLevel})` }">
            <!-- Renderizando desktop e mobile lado a lado -->
            <div v-for="telaObj in telas" :key="telaObj.nome" class="versoes">
              <!-- Desktop -->
              <div class="site desktop" :class="{ ativo: storePersonalizacao.telaSelecionada === telaObj.nome }">
                <!-- Menu condicional -->
                <Menu v-if="!['telaLogin', 'telaAlterarSenha', 'telaLoading'].includes(telaObj.nome)" @click="selecionarTela('menu')" class="menuTela" :class="{ marcado: storePersonalizacao.telaSelecionada === 'menu' }" />
                <component :is="telaObj.componente" @click="selecionarTela(telaObj.nome)" />
              </div>
              <!-- Mobile -->
              <div class="site mobile" :class="{ ativo: storePersonalizacao.telaSelecionada === telaObj.nome }">
                <component :is="telaObj.componente" @click="selecionarTela(telaObj.nome)" />
              </div>
            </div>
          </div>
        </div>
      </VueDraggableResizable>
    </div>
  </section>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeUnmount } from 'vue'
import { useStoreAreas, useStorePersonalizacao } from '@stores'
import { helperMobile } from '@helpers'
import VueDraggableResizable from 'vue-draggable-resizable'
import Svgs from '@svgs'
import TelaLogin from '../telas/Login.vue'
import TelaLoading from '../telas/Loading.vue'
import TelaSenha from '../telas/Senha.vue'
import TelaCursos from '../telas/Cursos.vue'
import TelaCurso from '../telas/Curso.vue'
import TelaModulo from '../telas/Modulo.vue'
import TelaAula from '../telas/Aula.vue'
import TelaPerfil from '../telas/Perfil.vue'
import Menu from '../telas/Menu.vue'

const storeAreas = useStoreAreas()
const storePersonalizacao = useStorePersonalizacao()

const tela = ref(null)
const acessoDesktop = ref(window.innerWidth >= 1024)

const state = reactive({
  tema: JSON.parse(localStorage.getItem('personalizacao-levelmember'))?.tema,
  componentesDesktop: [],
  componentesMobile: [],
  botaoZoomOut: false
})

const telas = [
  { nome: 'telaLogin', componente: TelaLogin },
  { nome: 'telaAlterarSenha', componente: TelaSenha },
  { nome: 'telaLoading', componente: TelaLoading },
  { nome: 'telaCursosArea', componente: TelaCursos },
  { nome: 'telaCurso', componente: TelaCurso },
  { nome: 'telaModulo', componente: TelaModulo },
  { nome: 'telaAula', componente: TelaAula },
  { nome: 'telaPerfil', componente: TelaPerfil }
]

function selecionarTela(telaSelecionada) {
  storePersonalizacao.telaSelecionada = telaSelecionada
}

function textoTema() {
  if (state.tema === 'claro') return 'Escuro'
  else if (state.tema === 'escuro') return 'Claro'
  else return 'Tema'
}

function alterarTema() {
  const dadosLocalStorage = JSON.parse(localStorage.getItem('personalizacao-levelmember')) || {}

  state.tema = state.tema === 'claro' ? 'escuro' : 'claro'

  localStorage.setItem('personalizacao-levelmember', JSON.stringify({ ...dadosLocalStorage, tema: state.tema }))

  document.body.setAttribute('tema', state.tema)
}

const initialZoom = window.innerWidth < 768 ? 0.1 : 0.25
const zoomLevel = ref(initialZoom)

const zoomIn = () => {
  zoomLevel.value += 0.05
  zoomBotaoDisabled()
}

const zoomOut = () => {
  zoomLevel.value = Math.max(zoomLevel.value - 0.05, 0.05)
  zoomBotaoDisabled()
}

const zoomInScroll = () => {
  zoomLevel.value += 0.01
  zoomBotaoDisabled()
}

const zoomOutScroll = () => {
  zoomLevel.value = Math.max(zoomLevel.value - 0.01, 0.05)
  zoomBotaoDisabled()
}

const zoomBotaoDisabled = () => {
  state.botaoZoomOut = zoomLevel.value <= 0.05
}

const adicionarClasseBloquear = () => {
  document.body.classList.add('bloquear')
}
const removerClasseBloquear = () => {
  document.body.classList.remove('bloquear')
}

const zoomComMouse = (event) => {
  if (event.metaKey || event.ctrlKey) {
    event.preventDefault()
    adicionarClasseBloquear()

    if (event.deltaY < 0) zoomInScroll()
    else if (event.deltaY > 0) zoomOutScroll()
  }
}

const lidarComTeclaPressionada = (event) => {
  if (event.metaKey || event.ctrlKey) adicionarClasseBloquear()
}

const lidarComTeclaLiberada = () => {
  removerClasseBloquear()
}

function centralizarTela() {
  const alturaDiv = document.querySelector('div.sites').offsetHeight
  const larguraDiv = document.querySelector('div.sites').offsetWidth
  const moveY = alturaDiv / 2 - 350
  let moveX

  if (helperMobile.detectarDispositivoMobile()) moveX = larguraDiv / 2 - 200
  else moveX = larguraDiv / 2 - 750

  window.scrollTo({ top: moveY, left: moveX, behavior: 'smooth' })
}

const removeClass = () => {
  if (tela.value && document.body.classList.contains('bloqueado')) {
    document.body.classList.remove('bloqueado')
  }
}

function verificarTela() {
  acessoDesktop.value = window.innerWidth >= 1024
}

onMounted(() => {
  window.addEventListener('resize', verificarTela)
  if (acessoDesktop.value) {
    centralizarTela()
  }
  window.addEventListener('wheel', zoomComMouse, { passive: false })
  window.addEventListener('keydown', lidarComTeclaPressionada)
  window.addEventListener('keyup', lidarComTeclaLiberada)
  window.addEventListener('resize', centralizarTela)
  if (tela.value) {
    tela.value.addEventListener('mouseover', removeClass)
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', verificarTela)
  window.removeEventListener('wheel', zoomComMouse, { passive: false })
  window.removeEventListener('keydown', lidarComTeclaPressionada)
  window.removeEventListener('keyup', lidarComTeclaLiberada)
})
</script>

<style scoped>
section.tela {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100dvw;
  height: 100dvh;
  position: relative;
  background-color: var(--cor-preto);
  scrollbar-width: none;
}

.avisoMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-1);
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 0 150px 0;
}

.avisoMobile .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--cor-cinza-3);
  margin: 0 0 30px 0;
}

.avisoMobile .icone svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
}

.avisoMobile p {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  width: 100%;
  max-width: 250px;
  line-height: 1.5;
  text-align: center;
}

.componente {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.botoes {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 250px;
  width: calc(100% - 500px);
  background-color: var(--cor-cinza-3);
  height: 50px;
  z-index: 9;
}

.botoes .botao {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0 10px;
  white-space: nowrap;
  transition: all 0.3s;
}

.botoes .botao p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.botoes .botao:disabled {
  pointer-events: none;
}

.botoes .botao:disabled p {
  color: var(--cor-cinza-5);
}

.botoes .botao:disabled svg {
  fill: var(--cor-cinza-5);
}

.botoes .botao:hover {
  opacity: 0.7;
}

.botoes .botao:active {
  opacity: 0.7;
}

.botoes .botao svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
  margin: 0 8px 0 0;
  transition: all 0.3s;
}

.cor {
  position: absolute;
  width: 100%;
  height: 100%;
}

.sites {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 100000px;
  min-height: 100000px;
  max-height: 100000px;
  width: 100000px;
  min-width: 100000px;
  max-width: 100000px;
  background-color: var(--cor-cinza-1);
  scrollbar-width: none;
}

.sites::-webkit-scrollbar {
  display: none;
}

.sites .conteudo {
  display: flex;
  align-items: flex-start;
  transition: transform 0.3s;
}

.site {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid var(--cor-cinza-3);
  position: relative;
  transition: all 0.3s;
}

.site::before {
  content: '';
  position: absolute;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  border: 2px solid var(--cor-cinza-1);
  top: -15px;
  left: -15px;
  pointer-events: none;
  transition: all 0.3s;
}

.site:hover::before {
  border: 2px solid var(--cor-branco);
}

.site.ativo::before {
  border: 2px solid var(--cor-laranja);
}

.menuTela::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid var(--cor-cinza-1);
  top: 0;
  left: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.menuTela:hover::before {
  border: 2px solid var(--cor-branco);
}

.menuTela.marcado::before {
  border: 2px solid var(--cor-laranja);
}

.versoes {
  display: flex;
  align-items: flex-start;
}

.site.desktop {
  max-width: 1500px;
  min-width: 1500px;
  margin: 0 100px 0 0;
}

.site.mobile {
  max-width: 414px;
  min-width: 414px;
  margin: 0 300px 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .botoes {
    justify-content: flex-start;
    top: 60px;
    left: 0;
    width: 100%;
    height: 40px;
    padding: 0 0 0 20px;
    overflow-y: scroll;
  }
  .botoes .botao {
    padding: 0 20px 0 0;
  }
}
</style>
