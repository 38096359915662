<template>
  <Modal nome="criarIntegracao">
    <label class="um">
      Plataforma
      <span>*</span>
    </label>
    <div class="select">
      <Svgs nome="setinha" />
      <select v-model="state.gateway">
        <option value="hotmart">Hotmart</option>
        <option value="kiwify">Kiwify</option>
        <option value="ticto">Ticto</option>
      </select>
    </div>

    <label>
      Nome da integração
      <span>*</span>
    </label>
    <input v-model="state.nome" type="text" spellCheck="false" placeholder="Ex: High Ticket" @keydown.enter="criarintegracao" />

    <label>
      Pontos na sua área ao comprar
      <span>*</span>
    </label>
    <div class="input">
      <Svgs class="icone" nome="dinheiro" />
      <input v-model="state.pontuacao" type="tel" spellCheck="false" placeholder="Ex: 100" @keydown.enter="criarintegracao" />
    </div>

    <label>
      Cursos que a integração vai liberar
      <span>*</span>
    </label>
    <Checklist :dados="state.cursos" />

    <SalvarModal nome="Criar Nova Configuração" @click="criarintegracao()" />
  </Modal>
</template>

<script setup>
import { inject, reactive, onMounted, computed } from 'vue'
import { useStoreModal, useStoreAreas, useStoreIntegracoes, useStoreCursos } from '@stores'

import Checklist from '@components/global/elementos/Checklist.vue'
import SalvarModal from '@components/global/elementos/SalvarModal.vue'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storeAreas = useStoreAreas()
const storeIntegracoes = useStoreIntegracoes()
const storeCursos = useStoreCursos()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('criarIntegracao'))

const state = reactive({
  cursos: [],
  nome: '',
  gateway: 'hotmart',
  pontuacao: ''
})

async function carregarTodosOsCursos() {
  const idArea = storeAreas.area?._id
  if (idArea) {
    await storeCursos.receberTodos(idArea)
    state.cursos = storeCursos.cursos.map((curso) => ({
      nome: curso.nome,
      id: curso._id,
      selecionado: false
    }))
  }
}

function carregarCampos() {
  state.gateway = dadosModal.value.id
  carregarTodosOsCursos()
}

function limparCampos() {
  state.nome = ''
  state.gateway = 'hotmart'
  state.pontuacao = ''
  state.cursos = []
}

async function criarintegracao() {
  if (!validarDados()) return

  const idArea = storeAreas.area?._id

  const payload = {
    idsCursos: state.cursos.filter((curso) => curso.selecionado).map((curso) => curso.id),
    nome: state.nome,
    gateway: state.gateway,
    numeroPontosAoComprar: state.pontuacao,
    ativo: true
  }

  const adicionadoOk = await storeIntegracoes.adicionar(idArea, payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarIntegracoes')

  await storeIntegracoes.receberTodos(idArea)

  const ultimaIntegracao = storeIntegracoes.integracoes.sort((a, b) => new Date(b.dataCriacao) - new Date(a.dataCriacao))[0]
  const idIntegracao = ultimaIntegracao._id

  storeModal.fecharModal('criarIntegracao')
  storeModal.abrirModalEdicao('editarWebhook', 'integracao', `Webhook para ${payload.gateway}`, idIntegracao)
  limparCampos()
}

function validarDados() {
  let mensagem
  if (!state.nome || !state.gateway || !state.pontuacao || !state.cursos) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
  emitter.on('carregarCampos', carregarCampos)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

label.um {
  margin: 0 0 10px 0;
}

label.link {
  display: flex;
  align-items: center;
}

label button {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-roxo);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  opacity: 0.6;
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-roxo);
  margin: 0 5px 0 0;
}

button.salvar {
  margin: 20px 0 0 0;
}
</style>
