<template>
  <Modal nome="editarWebhook">
    <div class="configuracao">
      <div class="titulo">
        <h3>Eventos que deve selecionar</h3>
        <p>Crie um webhook na {{ state.gateway }} com os seguintes eventos:</p>
      </div>
      <div class="item" v-for="evento in state.configuracaoSelecionada.eventos" :key="evento">
        <Svgs nome="check" />
        <p>{{ evento }}</p>
      </div>
      <div class="titulo dois">
        <h3>URL para colar no webhook da plataforma</h3>
        <p>Copie a URL abaixo e cole no webhook da {{ state.gateway }}</p>
        <textarea @click="copiarTexto" :value="state.configuracaoSelecionada.link" readonly v-redimensionar-textarea></textarea>
      </div>
    </div>

    <!-- <div class="tutorial" v-if="state.gateway">
      <h3>Aprenda a integrar</h3>
      <p>Fizemos um vídeo para você conseguir integrar a sua plataforma {{ state.gateway }} na Level Member</p>
      <div class="video">
        <div class="tamanho">
          <iframe src="https://www.youtube.com/embed/5uemBHUNoto?si=ql2iIYGnqTQEzvZ-" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
        </div>
      </div>
    </div> -->
  </Modal>
</template>

<script setup>
import { watch, computed, reactive, inject, onMounted } from 'vue'
import { useStoreModal, useStoreAreas, useStoreIntegracoes } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import Svgs from '@svgs'

const storeAreas = useStoreAreas()
const storeIntegracoes = useStoreIntegracoes()
const storeModal = useStoreModal()
const dadosModal = computed(() => storeModal.dadosDoModal('editarWebhook'))
const emitter = inject('emitter')

const state = reactive({
  configuracaoSelecionada: {},
  gateway: ''
})

async function pegarIdIntegracao() {
  const idArea = storeAreas.area?._id
  if (dadosModal.value.id) {
    await storeIntegracoes.receberPorId(idArea, dadosModal.value.id)
    state.configuracaoSelecionada = storeIntegracoes.integracao?.webhook
    state.gateway = storeIntegracoes.integracao?.gateway
  }
}

function copiarTexto(event) {
  const textarea = event.target
  textarea.select()
  document.execCommand('copy')
  const mensagem = 'Link copiado'
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: mensagem })
}

function limparCampos() {
  state.configuracaoSelecionada = {}
  state.gateway = ''
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
  emitter.on('carregarCampos', pegarIdIntegracao)
})
</script>

<style scoped>
label {
  margin: 20px 0 10px 0;
}

label.link {
  display: flex;
  align-items: center;
}

label button {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-roxo);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  opacity: 0.6;
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-roxo);
  margin: 0 5px 0 0;
}

.tutorial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  border-top: 1px solid var(--cor-cinza-3);
  margin: 30px 0 0 0;
  padding: 30px 0 0 0;
}

.tutorial h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.tutorial p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  width: 100%;
  max-width: 400px;
  margin: 0 0 20px 0;
}

.configuracao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.configuracao .titulo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.configuracao .titulo.dois {
  margin: 30px 0 0 0;
}

.configuracao .titulo h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.configuracao .titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.configuracao .item {
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
}

.configuracao .item svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-laranja);
  margin: 0 5px 0 0;
}

.configuracao .item p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-laranja);
}

.configuracao textarea {
  width: 100%;
  margin: 10px 0 0 0;
}

.video {
  width: 100%;
}

.video .tamanho {
  width: 100%;
  position: relative;
  padding-bottom: 56.52%;
}

.video .tamanho iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  border-radius: 10px;
}

button.salvarModal {
  margin: 20px 0 0 0;
}

.excluirModal {
  margin: 30px 0 0 0;
}
</style>
